
  export default {
    props: {
      keyId: {
        type: String,
        required: true
      }
    },
    data(){
      return {
        product: false,
        showingSidebar: true,
        lesson: false
      }
    },
    async created(){
      if(this.keyId){
        const key = this.keyId.substring(1)
        const data = await this.$player.get(key)
        try {
          this.product = data.product
          this.lesson = data.lesson
        } catch(err){ }
      }
    }
  }

