
      export default {
        props: {
          keyId: {
            type: String,
            required: true
          }
        },
        data() {
          return {
            course: {},
            isFetching: true,
            mainVideo: false,
            loggedIn: false,
            memberStatus: false,
            preorder: false,
            courseFields: ["id", "title", "short_description", "recommendations", "yotpo", "sales_score", "intro_video", "description", "videos",
              {
                "play_cover": {
                  "art": "169_1920"
                }
              }, "table_of_contents", "free_lessons", "educator", "yotpo", "truefire_items", "includes", "release_date:unixtime", "tags", "favorite", "status", "watch_link", "owns"
            ],
          }
        },
        methods: {
          updateVideo(newUrl, newCover) {
            if (process.client) {
              this.mainVideo = newUrl;
              this.mainCover = newCover;
            }
          },
          async getMemberInfo() {
            try {
              const member = await this.$member.get(['memberusername', 'tfu_status']);
              if(member.memberusername) {
                this.loggedIn = true;
                this.memberStatus = member.tfu_status;
              }
            } catch {}
          },
          jumpToPlayAndBuy() {
            const elem = this.$refs.purchase;
            const top = elem.offsetTop - 24;
            window.scrollTo({
              top: top,
              behavior: "instant"
            });
          }
        },
        async created() {
          //console.log("$fetchState", this.$fetchState)
          this.isFetching = true;
          this.getMemberInfo();
          this.course = await this.$course.getCourseInfo(this.keyId.replace('c', ''), this.courseFields);
          console.log(this.course);
          this.mainVideo = this.course.intro_video;
          this.mainCover = this.course.play_cover;
          this.preorder = this.course.status == 2 || this.course.status == 4;
    
          /* fix instances like you\'re to you're */
          this.course.description = this.course.description.replaceAll(/\\\'/g, "\'");
          this.isFetching = false;
        }
      }
      
      