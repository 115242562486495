
    export default {
        props: {
            courseID: Number,
            loggedIn: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                recommendations: [],
            }
        },
        methods :{
            async getRecommendations() {
                const postObj =  [
                    "id", "title", "url", "educator", { 
                        "image_url": {
                            "art": "VThumb_300"
                        }
                    }, "banner"
                ]
                try {
                    this.recommendations = await this.$course.getRecommendedCoursesByID(this.courseID, postObj);
                } catch {}
            }
        },
        created() {
            this.getRecommendations();
        }
    }
