
    export default {
        props: {
            priceInfo: Array,
            tooltip: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                bullets: [
                    `Add the download item to your cart.`,
                    `Go to your cart and select "Send as Gift".`,
                    `Enter the recipient's email address and click confirm.`,
                    `Apply payment and complete your order.`,
                    `Accept endless appreciation!`
                ]
            }
        },
    }
