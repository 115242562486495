
    export default {
        props: {
            includes: Array,
            release_date: Number,
            tags: Object
        },
        data() {
            return {
                features: [
                    {
                        gif: `//d2xkd1fof6iiv9.cloudfront.net/inc/img/all-access/2020/feature_videos/Renders/AllAccess_WebVid_r2_hi.mp4`,
                        title: `Multi-Angle HD Video Lessons`,
                        description: `Clearly see what the educator is demonstrating!`
                    },
                    {
                        gif: `//d2xkd1fof6iiv9.cloudfront.net/inc/img/all-access/2020/feature_videos/Renders/03_AllAccess_TabSync_hi.mp4`,
                        title: `Video Tab Sync & Fretboard View`,
                        description: `Easily follow along for quickest progress! Clearly see finger placement on the fretboard!`
                    },
                    {
                        gif: `//d2xkd1fof6iiv9.cloudfront.net/inc/img/all-access/2020/feature_videos/Renders/02_AllAccess_SlowMotion_hd.mp4`,
                        title: `Slow Motion & Looping`,
                        description: `Learn and Practice at your ideal speed! Choose any section of the lesson to focus on!`
                    }
                ],
                searchUrl: ``,
                releaseDate: ``,
            }
        },
        created() {
            this.searchUrl = window.location.origin + `/search/?q=`;
            const date = new Date(this.release_date * 1000);
            this.releaseDate = date.toLocaleDateString();
        }
    }
