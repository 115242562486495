
export default {
    props: {
        mobileNavShowing: {
            type: Boolean,
            default: false
        },
		navData: Array
    },
    data() {
        return {
			//linkClass: `d-block py-1 px-3 text-nowrap text-dark`,
			currMobileNav: `main`,
        }
    },
    watch: {
        mobileNavShowing() {
            this.currMobileNav = "main";
        }
    },
    methods: {
		navToNewPage(item) {
			window.location.href = item.url ? item.url : '/#';
		},
        async searchThisEducator(item) {
            try {
                if (item.search && item.search.set) {
                    await this.$search.setEducatorSet(item.search.set);
                }
            } catch(e) {
                console.log(e);
            }
            finally {
                window.location.href = window.location.origin + `/directory`;
            }
        }
    },
}
