
	export default {
		props: ['item', 'single'],
		data() {
			return {
				hover: false,
				progressIcon: 'far fa-circle',
			}
		},
		methods: {
			async clickLesson() {
				console.log('item id', this.item.id)
				const data = await this.$player.getLesson(this.item.id)
			}
		}
	}
