
export default {
    /* computed: {
         letsee() {
             return this.api.menu[0].subShow;
         },
     },
     watch: {
         letsee(l) {
             console.log("letsee", l);
         }
     },*/
    data() {
        return {
            linkClass: `d-block py-1 px-3 text-nowrap text-dark`,
            darkMode: false,
            mobileNavShowing: false,
            api: {
                menu: [{
                        label: "course",
                        url: "#",
                        subNav: [{
                                label: "30 Beginner Blues Guitar Licks You MUST Know",
                                url: "/i11875"
                            },
                            {
                                label: "Acoustic Rhythm Guitar Playbook",
                                url: "/i13190"
                            },
                            {
                                label: "Trading Solos: Slow Blues",
                                url: "/i13838"
                            },
                            {
                                label: "50 Progressive Blues Licks You MUST Know",
                                url: "/i9189"
                            },
                            {
                                label: "Hip Blues: Outside Lines",
                                url: "/i14810"
                            },
                            {
                                label: "Acoustic Crossover: Soloing",
                                url: "/i14818"
                            },
                            {
                                label: "50 Texas Blues Licks You MUST Know",
                                url: "/i9094"
                            },
                            {
                                label: "50 Blues Rhythms You MUST Know",
                                url: "/i9324"
                            },
                            {
                                label: "Trading Solos: Texas Blues",
                                url: "/i13676"
                            },
                            {
                                label: "Essentials: Modern Country Soloing",
                                url: "/i13731"
                            },
                            {
                                label: "Solo Factory: Jam Craft",
                                url: "/i14539"
                            },
                            {
                                label: "Essentials: Texas Blues Solos",
                                url: "/i11674"
                            },
                            {
                                label: "Blues Guitar Fakebook: Rhythm Vol. 1",
                                url: "/i12728"
                            },
                            {
                                label: "Slow Blues Guru",
                                url: "/i13157"
                            },
                            {
                                label: "Live Plus: Blues Rhythm Camp, Episode 01",
                                url: "/i16583"
                            },
                            {
                                label: "30 Beginner Acoustic Guitar Rhythms You MUST Know",
                                url: "/i12105"
                            },
                            {
                                label: "Rhythm Factory: Texas Blues",
                                url: "/i10699"
                            },
                            {
                                label: "Solo Factory: Texas Blues",
                                url: "/i9726"
                            },
                            {
                                label: "30 Modern Country Guitar Licks You MUST Know",
                                url: "/i12779"
                            }
                        ],
                        subShow: false
                    },
                    {
                        label: "itj",
                        url: "#",
                        subNav: [{
                            label: "In The Jam: Bar Room Grooves",
                            url: "/i14238"
                        }],
                        subShow: false
                    }
                ]
            },
            info: {
                educator: "Corey Congilio",
                home: "/e4200",
                social: [{
                        type: "facebook",
                        value: "https://facebook.com/coreycongiliomusic"
                    },
                    {
                        type: "twitter",
                        value: "https://twitter.com/coreycongilio"
                    },
                    {
                        type: "youtube",
                        value: "https://youtube.com/user/coreycongilio"
                    },
                    {
                        type: "instagram",
                        value: "https://instagram.com/coreycongilio"
                    },
                    {
                        type: "bandsintown",
                        value: "https://bandsintown.com/en/a/5553652-corey-congilio"
                    },
                    {
                        type: "spotify",
                        value: "https://open.spotify.com/artist/1KfQL0KSth4B5r0FZYiDQu"
                    }
                ],
                nav: [{
                        label: "course",
                        url: "#",
                        subNav: [{
                                label: "30 Beginner Blues Guitar Licks You MUST Know",
                                url: "/i11875"
                            },
                            {
                                label: "Acoustic Rhythm Guitar Playbook",
                                url: "/i13190"
                            },
                            {
                                label: "Trading Solos: Slow Blues",
                                url: "/i13838"
                            },
                            {
                                label: "50 Progressive Blues Licks You MUST Know",
                                url: "/i9189"
                            },
                            {
                                label: "Hip Blues: Outside Lines",
                                url: "/i14810"
                            },
                            {
                                label: "Acoustic Crossover: Soloing",
                                url: "/i14818"
                            },
                            {
                                label: "50 Texas Blues Licks You MUST Know",
                                url: "/i9094"
                            },
                            {
                                label: "50 Blues Rhythms You MUST Know",
                                url: "/i9324"
                            },
                            {
                                label: "Trading Solos: Texas Blues",
                                url: "/i13676"
                            },
                            {
                                label: "Essentials: Modern Country Soloing",
                                url: "/i13731"
                            },
                            {
                                label: "Solo Factory: Jam Craft",
                                url: "/i14539"
                            },
                            {
                                label: "Essentials: Texas Blues Solos",
                                url: "/i11674"
                            },
                            {
                                label: "Blues Guitar Fakebook: Rhythm Vol. 1",
                                url: "/i12728"
                            },
                            {
                                label: "Slow Blues Guru",
                                url: "/i13157"
                            },
                            {
                                label: "Live Plus: Blues Rhythm Camp, Episode 01",
                                url: "/i16583"
                            },
                            {
                                label: "30 Beginner Acoustic Guitar Rhythms You MUST Know",
                                url: "/i12105"
                            },
                            {
                                label: "Rhythm Factory: Texas Blues",
                                url: "/i10699"
                            },
                            {
                                label: "Solo Factory: Texas Blues",
                                url: "/i9726"
                            },
                            {
                                label: "30 Modern Country Guitar Licks You MUST Know",
                                url: "/i12779"
                            }
                        ],
                        subShow: false
                    },
                    {
                        label: "itj",
                        url: "#",
                        subNav: [{
                            label: "In The Jam: Bar Room Grooves",
                            url: "/i14238"
                        }],
                        subShow: false
                    }
                ]
            }
        }
    },

    methods: {
        showDropdown(item, i) {

            item.subShow = true;
            this.api.menu[i] = item;
            console.log(item.subShow);
            console.log(this.api.menu[i].subShow);
          //  this.$forceUpdate();
            /*console.log("mouseover", this.api.menu[i].label);
            console.log("before", this.api.menu[i]);
            this.api.menu[i].subShow = true;
            console.log("after", this.api.menu[i]);*/
        },
        hideDropdown(item, i) {
            item.subShow = false;
            this.api.menu[i] = item;

            console.log(item.subShow);
            console.log(this.api.menu[i].subShow);

            //  this.$refs.i.$forceUpdate();
            /* console.log("mouseleave", this.api.menu[i].label);
             console.log("before", this.api.menu[i]);
             this.api.menu[i].subShow = false;
             console.log("after", this.api.menu[i]);*/
        }
    },
    async created() {
        this.api = await this.$configs.nav();
        for (let i = 0; i < this.api.menu.length; i++) {
            this.api.menu[i]['subShow'] = false;
        }
    }
}
