
	export default {
		props: ['src'],
		data(){
			return {
				volumeMenu: false,
				rateMenu: false,
				volumeValue: 1,
				scrubVal: 0,
				progress: 0,
				duration: 100,
				timeLeft: '00:00',
				rates: [.5, 1, 1.5],
				activeRate: 1,
				playing: false,
				streamUrl: false,
				jamPlayer: false,
				scrubbing: false
			}
		},
		watch: {
			playing() {
				this.playing ? this.jamPlayer.play() : this.jamPlayer.pause()
				// if (this.playing && this.$root.playingVideo) {
				// 	this.$root.pauseVideo()
				// }
			},
			activeRate() {
				switch (this.activeRate) {
					case 0:
						this.jamPlayer.playbackRate = 0.5
						break;
					case 1:
						this.jamPlayer.playbackRate = 1
						break;
					case 2:
						this.jamPlayer.playbackRate = 1.5
						break;
				}
			}
		},
		created() {
			this.$nextTick(this.init)
		},
		methods: {
			init() {
				this.jamPlayer = document.getElementById('jamPlayer')
				this.jamPlayer.src = this.src
				this.loadJam()
			},
			closeVolumeMenu() {
				this.volumeMenu = false;
			},
			closeRateMenu() {
				this.rateMenu = false
			},
			loadJam() {
				this.listenProgress()
			},
			pauseJamTrack() {
				if (this.playing) {
					this.jamPlayer.pause()
					this.playing = false
				}
			},
			midScrub(e) {
				this.scrubbing = true
				this.scrubVal = e.target.value
				this.jamPlayer.currentTime = e.target.value
			},
			doScrub(e) {
				this.scrubVal = e.target.value
				this.jamPlayer.currentTime = e.target.value
				this.scrubbing = false;
			},
			listenProgress() {
				if (this.jamPlayer.currentTime && this.jamPlayer.duration) {
					const secondsLeft = Math.floor(this.jamPlayer.duration - this.jamPlayer.currentTime)
					let min = Math.floor(secondsLeft / 60)
					let sec = secondsLeft - min * 60
					if (min < 10) min = '0' + min
					if (sec < 10) sec = '0' + sec
					this.timeLeft = min + ':' + sec
					this.duration = this.jamPlayer.duration;
					this.progress = this.jamPlayer.currentTime / this.jamPlayer.duration * 100
					this.scrubVal = this.jamPlayer.currentTime
					this.playing = !this.jamPlayer.paused
					// if(this.$root.playingVideo) this.playing = !this.playing 
				}
				window.requestAnimationFrame(this.listenProgress)
			},
			changeVolume(e) {
				this.jamPlayer.volume = this.volumeValue
			},
		}
	}
