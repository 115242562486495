import { render, staticRenderFns } from "./try-for-free.vue?vue&type=template&id=54046f62"
import script from "./try-for-free.vue?vue&type=script&lang=js"
export * from "./try-for-free.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TfImg: require('/usr/src/app/components/tf/img.vue').default,ButtonOutlineLight: require('/usr/src/app/components/button/outline-light.vue').default,TextLearnMore: require('/usr/src/app/components/text/learn-more.vue').default,TfFrame: require('/usr/src/app/components/tf/frame.vue').default})
