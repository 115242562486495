
export default {
    props: {
        sortData: Array /*  sortData: [
                                        ...
                                {
                                    label: `Most Reviews`,
                                    value: `yotpo.total_reviews`
                                },
                                {
                                    label: `Highest Rated`,
                                    value: `yotpo.average_score`
                                },
                                {
                                    label: `Release Date - Newest to Oldest`,
                                    value: `release_date`
                                },...
                            ]
                        */
    },
    data() {
        return {
            sortBy: "",
            didInit: false
        }
    },

    watch: {
        sortBy(sort) {
            if(this.didInit) this.$emit("update-sort", sort);
            else this.didInit = true
        },      
    },
    created() {
        if (this.sortData && this.sortData.length && this.sortData[0].value) {
            this.sortBy = this.sortData[0].value;
        }
    }
}
