import { render, staticRenderFns } from "./tabsync.vue?vue&type=template&id=0d665435"
import script from "./tabsync.vue?vue&type=script&lang=js"
export * from "./tabsync.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports