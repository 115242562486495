


	export default {
		data () {
			return {
				member: false,
				darkMode: false,
				helpBlurb: `Need help? Call 1-800-222-3366`,
				linkClass: `d-block py-1 px-3 text-no-wrap body-2 hover-block-link`,
				tfCashHover: false,
				// logged in left hand side
				profileNav: {
					showing: false,
					items: [
						{
							icon: `user`,
							label: `Profile`,
							url: `/dashboard/account-profile/`
						},
						{
							icon: `gear`,
							label: `Account Overview`,
							url: `/dashboard/account-overview/`
						},
						{
							icon: `arrows-rotate`,
							label: `Subscriptions`,
							url: `/dashboard/subscriptions/`
						},
						{
							icon: `tags`,
							label: `Billing & Shipping`,
							url: `/dashboard/account-billing-shipping/`
						},
						{
							icon: `calendar`,
							label: `Order History`,
							url: `/dashboard/account-order-history/`
						},
						{
							icon: `envelope`,
							label: `Email Prefs`,
							url: `/dashboard/account-email-preferences/`
						},
					]
				},

				allAccess: {
					img: `https://d2xkd1fof6iiv9.cloudfront.net/images/all-access/aabanner.png`,
					url: `/all-access/upgrade-now`
				},

				userNav: {
					currentNav: false,
					loggedIn: [
						{
							icon: `house`,
							label: `Dashboard`,
							url: `/dashboard`,
							subShow: false,
							subNav: [
								{
									icon: `table-cells`,
									label: `My Courses`,
									url: `/dashboard/my-courses`
								},
								{
									icon: `guitar`,
									label: `My Recommendations`,
									url: `/dashboard/my-recommendations`
								},
								{
									icon: `music`,
									label: `My Jams`,
									url: `/dashboard/my-jams`
								},
								{
									icon: `compact-disc`,
									label: `My Song Lessons`,
									url: `/dashboard/my-song-lessons`
								},
								{
									icon: `list`,
									label: `My Playlists`,
									url: `/dashboard/my-playlists`
								},
								{
									icon: `map`,
									label: `My Learning Paths`,
									url: `/dashboard/my-learning-paths`
								},
								{
									icon: `play`,
									label: `My Channels`,
									url: `/dashboard/my-channels`
								},
								{
									icon: `envelope`,
									label: `My Inbox`,
									url: `/dashboard/my-inbox`
								},
								{
									icon: `comments`,
									label: `My Private Lesson`,
									url: `/dashboard/my-private-lessons`
								},
								{
									icon: `cloud-arrow-down`,
									label: `My Downloads`,
									url: `/dashboard/my-downloads`
								}
							]
						},
						{
							icon: `table-cells`,
							label: `My Courses`,
							url: `/dashboard/my-courses`,
							noMobile: true
						},
						{
							icon: `clock`,
							label: `Recently Watched`,
							url: `/dashboard`
						},
						{
							icon: `star`,
							label: `Favorites`,
							url: `/dashboard/my-courses?collection=favorites`,
							subShow: false,
							subNav: [
								{
									icon: `chalkboard-user`,
									label: `Courses`,
									url: `/dashboard/my-courses?collection=favorites`
								},
								{
									icon: `play`,
									label: `Lessons`,
									url: `/dashboard/favorite-lessons`
								},
								{
									icon: `users`,
									label: `Educators`,
									url: `/dashboard/favorite-educators`
								},
								{
									icon: `music`,
									label: `Jams`,
									url: `/dashboard/favorite-jams`
								},
								{
									icon: `list`,
									label: `Playlists`,
									url: `/dashboard/favorite-playlists`
								},
								{
									icon: `play`,
									label: `Channels`,
									url: `/dashboard/favorite-channels`
								}
							]
						},
						{
							icon: `circle-question`,
							label: `Help`,
							url: `help`
						}
					],
					educator: [
						{
							icon: `gauge`,
							label: `Educator Dashboard`,
							url: `/dashboard`,
							subShow: false,
							subNav: [
								{
									icon: `gear`,
									label: `Classroom Manager`,
									url: `/classrooms/teacher_admin.html`,
									condition: `classroom_instructor`
								},
								{
									icon: `envelope`,
									label: `Inbox`,
									url: `/dashboard/my-inbox`,
									condition: `channel_owner`
								},
								{
									icon: `play`,
									label: `Channel Manager`,
									url: `/dashboard/channel-manager-new`,
									condition: `channel_owner`
								},
								{
									icon: `right-left`,
									label: `Private Lessons`,
									url: `/dashboard/my-private-lessons`,
									condition: `private_lessons_educator`
								},
								{
									icon: `film`,
									label: `Indie Course Producer`,
									url: `/dashboard/indie-producer`,
									condition: `foundry_owner`
								},
								{
									icon: `wrench`,
									label: `Sandbox`,
									url: `/dashboard/educator-sandbox`
								},
								{
									icon: `user`,
									label: `Public Profile`,
									url: `/dashboard/educator-profile`
								},
								{
									icon: `chart-simple`,
									label: `Statement`,
									url: `/dashboard/educator-statement`
								},
								{
									icon: `bullhorn`,
									label: `Marketing Toolkit`,
									url: `/dashboard/educator-marketing-toolkit`
								},
								{
									icon: `gift`,
									label: `Merch`,
									url: `/dashboard/educator-ordering`
								}
							]
						},
						{
							icon: `file`,
							label: `Statement`,
							url: `/dashboard/educator-statement/`
						},
						{
							icon: `list`,
							label: `Marketing Toolkit`,
							url: `/dashboard/educator-marketing-toolkit`
						},
						{
							icon: `house`,
							label: `Working With`,
							url: `https://workingwith.truefire.com/?_gl=1*jaunig*_ga*MTk1MzM1NzI4Ni4xNjk2MzQ1MzYz*_ga_SLG7T6TCHX*MTY5NjM0NTM2My4xLjEuMTY5NjM0NzYzMy4wLjAuMA..`
						},
					],
					notLoggedIn: [
						{
							icon: `circle-info`,
							label: `About TrueFire`,
							url: `/about`
						},
						{
							icon: `circle-arrow-up`,
							label: `All Access`,
							url: `/all-access`
						},
						{
							icon: `circle-question`,
							label: `Help`,
							url: `/help`
						}
					]
				}

			}
		},
		mounted(){
			this.getUser();
		},
		methods: {
			async getUser(){
				const result = await this.$member.get(['memberemail', 'avatar', 'memberusername', 'memberstatus', 'memberbalance', 'tfu_status', 'educator_navigation']);
				if(result.memberusername) {
					this.member = result;
					this.userNav.currentNav = this.member.educator_navigation ? 'educator' : 'loggedIn';
				}
				else this.userNav.currentNav = 'notLoggedIn';
			}
		},
		async fetch(){
			this.darkMode = await this.$member.config.darkMode()
		}
	}
