import { render, staticRenderFns } from "./page.vue?vue&type=template&id=9635887a"
import script from "./page.vue?vue&type=script&lang=js"
export * from "./page.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VideoPlayer: require('/usr/src/app/components/video/player.vue').default,BuyPrice: require('/usr/src/app/components/buy/price.vue').default,TfFrame: require('/usr/src/app/components/tf/frame.vue').default,TextListFloat: require('/usr/src/app/components/text/list-float.vue').default,TextTitleBlock: require('/usr/src/app/components/text/title-block.vue').default,ItjFeatures: require('/usr/src/app/components/itj/features.vue').default,ItjAbout: require('/usr/src/app/components/itj/about.vue').default,ItjRecommendations: require('/usr/src/app/components/itj/recommendations.vue').default,TfYotpoReviews: require('/usr/src/app/components/tf/yotpo-reviews.vue').default,ThumbnailStars: require('/usr/src/app/components/thumbnail/stars.vue').default,BuyNow: require('/usr/src/app/components/buy/now.vue').default,ButtonGiftAddShare: require('/usr/src/app/components/button/gift-add-share.vue').default,ItjTryForFree: require('/usr/src/app/components/itj/try-for-free.vue').default})
