
	export default {
		props: {
			showSlider: Boolean,
		},
		methods: {
			toggleSlider() {
				this.$emit('toggleSlider');
			},
			updateVolume() {
				const slider = this.$refs.slider;
				slider.setAttribute("style", `background: linear-gradient(to right, rgb(166, 75, 30) 0%, rgb(166, 75, 30) ${slider.value}%, rgb(51, 51, 51) ${slider.value}%, rgb(51, 51, 51) 100%);`);
				this.$emit('update', slider.value);
			},
		}
	}
