
export default {
    props: {
        name: String,
        id: Number
    },
    data() {
        return {
            email: ``,
            loop: false,
            subscribed: false,
            welcomeText: `Get ready to dive deep into the world of guitar magic with me.
            From killer riffs to soulful melodies, we're about to unlock some serious musical mojo together.
            <br/><br/>
            Stay tuned for exclusive tips, tricks, and maybe even a riff or two that'll blow your mind.
            Let's rock this journey and unleash your inner guitar hero!`
        }
    },
    methods: {
        submitForm() {
            const postObj = {
                "educator":{
                    "no_cache":true,
                    "id": this.id,
                    "add_email_to_subscription_list":{
                        "email": this.email,
                        "also_truefire": this.loop
                    },
                    "fields":["authorid"]
                }
            }
            const response = this.$educator.subscribe(postObj);

            if (response) this.subscribed = true;
        }
    },
}
