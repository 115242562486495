import { render, staticRenderFns } from "./features.vue?vue&type=template&id=3ec518b2"
import script from "./features.vue?vue&type=script&lang=js"
export * from "./features.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TfImg: require('/usr/src/app/components/tf/img.vue').default,TextTitleBlock: require('/usr/src/app/components/text/title-block.vue').default})
