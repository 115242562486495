
export default {
    name:"ITJ",
    props: {
      keyId: {
        type: String,
        required: true
      }
    },
    data() {
        return {
            itj: {},
            table_of_contents: [],
            plusNetworkId: null,
            altTitle: `In the Jam - TrueFire`,
            altDescription: `There’s no better way to develop your improvisational skills than getting up on the bandstand and jamming with a solid rhythm section and other players. TrueFire’s suite of jamming learning tools brings that bandstand into your practice room in 3 distinctive formats: In The Jams, Multi-Track Audio Jams, and Jam Packs.`
        }
    },
    head() {
        return {
            title: this.itj && this.itj.title ? this.itj.title + ` - TrueFire`: this.altTitle,
            meta: [
                { name: `description`, content: this.itj && this.itj.description ? this.itj.description : this.altDescription }
            ],
            link:[
              { rel: 'canonical', href: this.itj && this.itj.canonical ? this.itj.canonical : "https://truefire.com/online-guitar-lessons" },
            ],
        }
    },
    methods: {
        jump(elem, top=0) {
            window.scrollTo({
            top: top,
            behavior: "instant"
            });
        },
        jumpToYotpo() {
            const elem = this.$refs.yotpo;
            const top = elem.offsetTop;
            this.jump(elem, top);
        },
    },
    async fetch() {
        this.plusNetworkId = await this.$configs.plusNetwork();
        const postObj = [
            'id', 'intro_video', 
            {
                'player_cover': {
                    'art': '169_1920'
                }
            },
            'title', 'description', 'truefire_items', 'songs',
            'yotpo', 'canonical'
        ]
        this.itj = await this.$itj.getItjInfo(this.keyId, postObj);
        this.itj.songs.forEach( (item) => {
            this.table_of_contents.push(item.title);
        })
    }
}
