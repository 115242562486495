
  export default {
    props: {
      keyId: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        course: {},
        isFetching: true,
        mainVideo: ``,
        loggedIn: false,
        memberStatus: ``,
        preorder: false,
        priceInfo: {},
        courseFields: ["id", "title", "short_description", "recommendations", "yotpo", "sales_score", "intro_video", "description", "videos",
          {
            "play_cover": {
              "art": "169_1920"
            }
          }, "table_of_contents", "free_lessons", "educator", "yotpo", "truefire_items", "educator_items", "includes", "release_date:unixtime", "tags", "favorite", "status", "watch_link", "owns",
          "canonical"
        ],
        plusNetworkId: false,
        altTitle: `Guitar Lessons - TrueFire`,
        altDescription: `TrueFire has the best online guitar courses for beginners and guitarists of all skill levels.`
      }
    },
    head() {
        return {
            title: this.course && this.course.title ? this.course.title : this.altTitle,
            meta: [
                { name: `description`, content: this.course && this.course.short_description ? this.course.short_description : this.altDescription }
            ],
            link:[
              { rel: 'canonical', href: this.course && this.course.canonical ? this.course.canonical : "https://truefire.com/online-guitar-lessons" },
            ],
        }
    },
    methods: {
      updateVideo(newUrl, newCover) {
        if (process.client) {
          this.mainVideo = newUrl;
          this.mainCover = newCover;
        }
      },
      async getMemberInfo() {
        try {
          const member = await this.$member.get(['memberusername', 'tfu_status']);
          if(member.memberusername) {
            this.loggedIn = true;
            this.memberStatus = member.tfu_status;
          }
        } catch {}
      },
      jump(elem, top=0) {
        window.scrollTo({
          top: top,
          behavior: "instant"
        });
      },
      jumpToPlayAndBuy() {
        const elem = this.$refs.purchase;
        const top = elem.offsetTop - 24;
        this.jump(elem, top);
      },
      jumpToYotpo() {
        const elem = this.$refs.yotpo;
        const top = elem.offsetTop;
        this.jump(elem, top);
      }
    },
    async fetch() {
      //console.log("$fetchState", this.$fetchState)
      this.plusNetworkId = await this.$configs.plusNetwork();
      this.isFetching = true;
      this.getMemberInfo();
      try {
        this.course = await this.$course.getCourseInfo(this.keyId.replace('c', ''), this.courseFields);
        console.log("course:", this.course);
        this.mainVideo = this.course.intro_video;
        this.mainCover = this.course.play_cover;
        if (!this.plusNetworkId) {
          this.priceInfo = this.course.truefire_items && this.course.truefire_items.length ? this.course.truefire_items : {};
        }
        else {
          this.priceInfo = this.course.educator_items && this.course.educator_items.length ? this.course.educator_items : {};
        }
        this.preorder = this.course.status == 2 || this.course.status == 4;

        /* fix instances like you\'re to you're */
        this.course.description = this.course.description ? this.course.description.replaceAll(/\\\'/g, "\'") : '';
      }
      catch(e) {
        console.log("Error building course-page component -", e);
      }
      this.isFetching = false;
    }
  }
  
  