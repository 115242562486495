
export default {
    props: {
        navData: Array
    },
    data() {
        return {
            //linkClass: `d-block py-1 px-3 text-nowrap text-dark`,
            nav: []
        }
    },
    methods: {
        showDropdown(i) {
            this.nav[i].subShow = true;
            this.$forceUpdate();
        },
        hideDropdown(i) {
            this.nav[i].subShow = false;
            this.$forceUpdate();
        },
        async searchThisEducator(item) {
            try {
                if (item.search && item.search.set) {
                    console.log('search', item.search.set)
                    await this.$search.setEducatorSet(item.search.set);
                }
            } catch (e) {
                console.log(e);
            }
            finally {
                window.location.href = window.location.origin + `/directory`;
            }
        }
    },
    created() {
        this.nav = this.navData;
    }
}
