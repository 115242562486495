

	export default {
		props: {
			stars: Object,
			inline: {
				type: Boolean,
				default: false
			},
			separate: {
				type: Boolean,
				default: false
			},
			size: {
				type: String,
				default: `1x`
			}
		},
		computed: {
			isInline() {
				return this.inline ? 'd-inline' : '';
			},
			soft() {
				return this.inline ? '' : 'text-secondary fst-italic';
			},
			space() {
				return this.separate ? 'd-flex justify-content-between' : '';
			}
		}
	}
