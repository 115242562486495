
	export default {
		props: ['data', 'toggle'],
		data(){
			return {
				loaded: false,
				gridView: true,
				currentPage: 0,
				pageLimit: 6,
				totalPages: 0,
				offset: 0,
				numPageOptions: 3,
				currentSort: 'release_date',
				sortDir: true,
				storeType: 'course'
			}
		},
		created(){
			console.log('input data', this.data)
			this.totalPages = Math.ceil(this.data.length / this.pageLimit)
			this.setupDates()
			if(this.totalPages < this.numPageOptions) this.numPageOptions = this.totalPages
		},
		methods: {
			setupDates(){
				let tempDate;
				for(let i = 0; i < this.data.length; i++){
					if(this.data[i].release_date){
						tempDate = new Date(this.data[i].release_date)
						this.data[i].dateString = tempDate.getTime()
						this.data[i].release_date = tempDate.toLocaleDateString('en-US')
					}
				}
				this.sortData('release_date')
				this.loaded = true
			},
			sortData(input){
				console.log('sort by ' + input)
				this.data.sort((a, b) => {
					if(a[input] > b[input]) return this.sortDir ? -1 : 1
					else if(b[input] > a[input]) return this.sortDir ? 1 : -1
					return 0
				})
				if(this.currentSort != input) this.sortDir = true
				else this.sortDir = !this.sortDir
				this.currentSort = input
			}
		},
		watch: {
			currentPage(){
				if(this.currentPage < 0) this.currentPage = 0;
				else if(this.currentPage > this.totalPages) this.currentPage = this.totalPages
				this.numPageOptions = Math.min(3, Math.ceil(this.data.length / this.pageLimit))

				if (this.currentPage == 0 || this.currentPage == 1) this.offset = 0;
				else if (this.currentPage == this.totalPages) this.offset = this.currentPage - 3;
				else this.offset = this.currentPage - 2;
			}
		}
	}
