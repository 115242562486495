import { render, staticRenderFns } from "./gift-add-share.vue?vue&type=template&id=0990c7c1"
import script from "./gift-add-share.vue?vue&type=script&lang=js"
export * from "./gift-add-share.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ButtonOutlineLight: require('/usr/src/app/components/button/outline-light.vue').default,ModalGift: require('/usr/src/app/components/modal/gift.vue').default,ModalBasic: require('/usr/src/app/components/modal/basic.vue').default,ModalCollections: require('/usr/src/app/components/modal/collections.vue').default,ModalShare: require('/usr/src/app/components/modal/share.vue').default})
