
export default {
    data() {
        return {    
            about: [
                {
                    title: `Downloads`,
                    description: `Download and work with all your purchased courses offline in the new TrueFire app for Windows, Mac, iOS, or Android.`,
                    expand: false
                },
                {
                    title: `Streaming`,
                    description: `Whenever you purchase a course, you can stream it online and via mobile devices, including all video lessons, tabs, charts, jam tracks, and additional materials.`,
                    expand: false
                },
                {
                    title: `TrueFire Cash`,
                    description: `You earn TrueFire Cash back with every purchase and can use it for up to 25% off future purchases. Combine with All Access Discounts for max savings.`,
                    expand: false
                },
                {
                    title: `All Access Discounts`,
                    description: `All Access Students get an extra 20% off all full-priced courses (not on sale).`,
                    learnMore: `https://truefire.com/all-access/upgrade-now`,
                    expand: false
                },
                {
                    title: `Owner Discount`,
                    description: `You don't have to pay for lessons from other courses you already own. Simple as that!`,
                    expand: false
                },
                {
                    title: `Satisfaction Guarantee`,
                    description: `Your 100% satisfaction with TrueFire’s educational offerings is our #1 concern and we'll jump through hoops of fire to keep you happy, smiling and extremely well educated.`,
                    learnMore: `https://truefire.com/satisfaction-guarantee`,
                    expand: false
                }
            ]
        }
    },
    methods: {
        hideItems() {
            for (let i = 0; i < this.about.length; i++) {
                this.about[i].expand = false;
            }
        },
        expandItem(i) {
            this.hideItems();
            this.about[i].expand = true;
        },
    },
    created() {
        this.hideItems();
    }
}
