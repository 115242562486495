import { render, staticRenderFns } from "./share.vue?vue&type=template&id=3e7f5914&scoped=true"
import script from "./share.vue?vue&type=script&lang=js"
export * from "./share.vue?vue&type=script&lang=js"
import style0 from "./share.vue?vue&type=style&index=0&id=3e7f5914&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e7f5914",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ButtonOutlineLight: require('/usr/src/app/components/button/outline-light.vue').default})
