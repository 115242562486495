
    export default {
        props: {
            productId: Number,
            text: {
                type: String,
                default: `Add to Cart`
            }
        },
        data() {
            return {
                added: false
            }
        },
        created() {
            this.checkAdded();
        },
        methods: {
            async checkAdded() {
                this.added = await this.$cart.checkIfItemInCart(this.productId);
            },
            async addItem(){
                await this.$cart.addItemToCart(this.productId);
                this.added = !this.added;
            }
        }
    }
