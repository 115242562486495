
export default {
    data() {
        return {
            title: `In The Jam Features`,
            description: `TrueFire's In The Jam delivers an unparalleled jamming experience for the practicing musician. The next best thing to being there live, In The Jam puts YOU in the jam with top artists.
                <br/><br/>
                Each edition of In The Jam includes 10 multi-track video jams organized into separate video and audio tracks for each of the instruments. You can mute, solo or adjust the volume of any track. Plus, each jam includes a lead sheet chart showing the chord changes and general structure of the jam.
                <br/><br/>
                There's no better way to develop your improvisational skills than getting up on the bandstand and jamming with a solid rhythm section and other players. In The Jam brings that bandstand into your practice room!`,
            features: [
                {
                    title: `Jam with Top Pros!`,
                    description: `There's no better way to develop your improvisational skills than getting up on the bandstand and jamming with a solid rhythm section and top-notch players. In The Jam brings that bandstand and the worlds TOP players right to your practice room!`,
                    image: `https://d2xkd1fof6iiv9.cloudfront.net/images/in-the-jam/toppros@2x.jpg`
                },
                {
                    title: `For All Instruments!`,
                    description: `You can mute, solo or adjust the volume of any track. Play guitar, bass, keys or drums? Mute those tracks and jump in. Whatever your instrument (even horns and vocals!), mix the jam anyway you want and play your heart out.`,
                    image: `https://d2xkd1fof6iiv9.cloudfront.net/images/in-the-jam/allinstruments@2x.jpg`
                },
                {
                    title: `You Control the Mix!`,
                    description: `In addition to controlling all of the audio tracks, In The Jam also allows you to change which video is being displayed at any time so that you can focus on the instrument of your choice.`,
                    image: `https://d2xkd1fof6iiv9.cloudfront.net/images/in-the-jam/mix.jpg`
                },
                {
                    title: `Artist Commentaries!`,
                    description: `In most editions, the featured artists provide narrative commentary, over the full length of the track, revealing how they've approached their parts in each of the jams and how they interact creatively with the other musicians.`,
                    image: `https://d2xkd1fof6iiv9.cloudfront.net/images/in-the-jam/commentary@2x.jpg`
                },
                {
                    title: `Lead Sheets, Guitar Tab, & Notation!`,
                    description: `Each multi-track video jam also includes a lead sheet chart showing the chord changes and general structure of the jam. Guitar tab and standard notation is always provided for the guitar parts.`,
                    image: `https://d2xkd1fof6iiv9.cloudfront.net/images/in-the-jam/charts@2x.jpg`
                },
            ]
        }
    }
}
