
	export default {
		props: {
			allTags: Object,
		},
		data() {
			return {
				checkedNames: []
			}   
		},
		methods: {
			filterContent() {
				this.$emit("filter-content", this.$parent.selectedTags);
			}
		}
	}
