
export default {
    data() {
        return {
            // Add data here
        }
    },
    async mounted() {
       const nav = await this.$configs.nav();
       console.log(`nav`,nav);


        const nonce = '16fd6a09-1b54-4059-8eaf-2f8361016571';
        await this.$member.partnership.fromNonce(nonce);
        const player_config1 = this.$member.config.player();
        console.log(`player_config`,player_config1);
        
        //Exmple to get member and player confirguration from pin and formKey
        
        const pin = '7GEXA';
        const formKey = 'fe301e1f-5e0e-482d-afd6-4b959c96e16c';
        await this.$member.partnership.fromPin(pin,formKey);
        const player_config2= this.$member.config.player();
        console.log(`player_config`,player_config2);


        //resend email for pin from
        await this.$member.partnership.resendPin(formKey);

    }
}
