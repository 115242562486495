
export default {
    data() {
        return {
            title: `Try In The Jam For Free!`,
            logoImg: `https://d2xkd1fof6iiv9.cloudfront.net/images/in-the-jam/desktop-icon-small.png`,
            download: {
                text: `Download the TrueFire 3 app now to get a free download of the In The Jam: Free Sampler. Try out all the cool features and functionality!`,
                url: `/apps`
            },
            satisfaction: {
                image: `https://d2xkd1fof6iiv9.cloudfront.net/images/sg/450wide.png`,
                text: `Your 100% satisfaction with TrueFire’s educational offerings is our #1 concern and we'll jump through hoops of fire to keep you happy, smiling and extremely well educated.`,
                url: `/satisfaction-guarantee`
            }

        }
    }
}
