
export default {
    props: {
        title: {
            type: String,
            default: `Buy Now`
        },
        productInfo: Array,
        loggedIn: {
            type: Boolean,
            default: false
        },
        showTooltip: {
            type: Boolean,
            default: true
        }
    }
}
