import { render, staticRenderFns } from "./course.vue?vue&type=template&id=02ec434a&scoped=true"
import script from "./course.vue?vue&type=script&lang=js"
export * from "./course.vue?vue&type=script&lang=js"
import style0 from "./course.vue?vue&type=style&index=0&id=02ec434a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02ec434a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TfImg: require('/usr/src/app/components/tf/img.vue').default,ThumbnailStars: require('/usr/src/app/components/thumbnail/stars.vue').default,ModalCourseMore: require('/usr/src/app/components/modal/course-more.vue').default,ModalBasic: require('/usr/src/app/components/modal/basic.vue').default,ModalCollections: require('/usr/src/app/components/modal/collections.vue').default})
