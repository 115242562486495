import { render, staticRenderFns } from "./recommendations.vue?vue&type=template&id=33775fcb"
import script from "./recommendations.vue?vue&type=script&lang=js"
export * from "./recommendations.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ThumbnailItj: require('/usr/src/app/components/thumbnail/itj.vue').default,GridDynamic: require('/usr/src/app/components/grid/dynamic.vue').default,TextTitleBlock: require('/usr/src/app/components/text/title-block.vue').default})
