
  export default {
  	props: ['product'],
  	created(){
  		this.$nextTick(this.setupReviews)
  	},
  	methods: {
  		async setupReviews(){
			const eplus = await this.$configs.plusNetwork();
			console.log("EPLUS", eplus)
  			if(Yotpo){
					const yotpoApi = new Yotpo.API(yotpo), yotpoObj = {
						target: 'yotpoComments',
						id: 'c' + this.product.id,
						name: this.product.title,
						url: `https://truefire.com/c${this.product.id}`,
						image: 'https://d2xkd1fof6iiv9.cloudfront.net/images/courses/' + this.product.id + '/vthumb_300.jpg'
					}
					if(this.product.truefire_items && (this.product.truefire_items.length))
						yotpoObj.price =  this.product.truefire_items[0].price
					const price = yotpoObj.price ? 'data-price="' + yotpoObj.price + '"' : '',
						currency = yotpoObj.price ? 'data-currency="USD"': '',
						desc = yotpoObj.description ? 'data-description="' + yotpoObj.description + '"' : '';
					let widgetEl = '<div data-product-id="' + yotpoObj.id + '" ' + price + ' ' + currency + ' data-name="' + yotpoObj.name + '" data-url="' + yotpoObj.url + '" data-image-url="' + yotpoObj.image + '" ' + desc +
						'class="yotpo yotpo-main-widget"></div>'
					if (eplus) widgetEl = '<div><h3 class="yotpo mt-5 mb-3 text-secondary fw-light">Reviews</h3><div id="yotpo-testimonials-custom-tab"></div></div>';
					document.getElementById(yotpoObj.target).innerHTML = widgetEl;
					this.$nextTick(() => {
						yotpoApi.refreshWidgets()
					})
				}
  		}
  	}
  }
