
export default {
    data() {
        return {
            title: `About In The Jam`,
            subtitle: `Multi-Track Video Jams - You Control the Mix!`,
            videoUrl: `https://www.youtube.com/embed/86kgSl5OLBc`,
            features: {
                title: `In The Jam is downloadable software for Windows, Mac, and iPad. Features include: `,
                items: [
                    `Multi-Angle Videos`,
                    `Mixable Multi-Track Audio`,
                    `Artist Commentary`,
                    `Guitar Tab & Notation`,
                    `Lead Sheets`
                ]
            }
        }
    }
}
