
	export default {
		props: {
			keyId: {
				type: String,
				required: true
			}
		},
		data(){
			return {
				educator: false,
				name: ``,
				altTitle: `Guitar Lessons - TrueFire`,
				altDescription: `Learn how to play guitar with free online guitar lessons at TrueFire.`
			}
		},
		head() {
			return {
				title: this.name ? this.name + ` Online Guitar Lessons - TrueFire` : this.altTitle,
				meta: [
					{ name: `description`, content: this.name ? `Learn how to play guitar with free online guitar lessons by ` + this.name + ` at TrueFire.` : this.altDescription }
				],
				link:[
					{ rel: 'canonical', href: this.educator && this.educator.canonical ? this.educator.canonical : "https://truefire.com/online-guitar-lessons" },
				],
			}
		},
		async fetch(){
			try {
				const educator = await this.$educator.get(this.keyId.substring(1),
					[
						'id',
						'authorid',
						'authorfirstname',
						'authorlastname',
						'authorinfo:clean',
						'courses',
						'live',
						'raves',
						'itjs',
						'channels',
						'classrooms',
						'jams',
						'yotpo',
						'private_lessons',
						'bandsintown',
						'spotlight',
						'canonical'
					]
				);
				this.educator = educator
				this.name = this.educator.authorfirstname && this.educator.authorlastname ? this.educator.authorfirstname + ` ` + this.educator.authorlastname : ``
			} catch(err){}
		}
	}

