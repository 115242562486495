import { render, staticRenderFns } from "./toc.vue?vue&type=template&id=37d6afd2&scoped=true"
import script from "./toc.vue?vue&type=script&lang=js"
export * from "./toc.vue?vue&type=script&lang=js"
import style0 from "./toc.vue?vue&type=style&index=0&id=37d6afd2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37d6afd2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PlayerTocLesson: require('/usr/src/app/components/player/toc-lesson.vue').default})
