
	export default {
		props: {
			input: {
				type: Object,
				required: false
			},
			configId:{
				type: String,
				required: false
			}
		},
		data() {
			return {
				templateData: false
			}
		},
		async fetch(){
			if (this.input) {
				this.templateData = this.input
				return
			}
			if (this.configId) {
				this.templateData = await this.$configs.get(this.configId)
				return
			}
		}
	}
