
	export default {
		name: 'PinModal',
		data(){
			return {
				pinForm: false,
				active: true,
				pinValue: ''
			}
		},
		async created(){
			this.pinForm = await this.$member.config.pinForm()
			this.pinValue = this.pinForm.pin
		},
		methods: {
			async submitPinForm(){
				console.log('submitting', this.pinValue)
        await this.$member.partnership.fromPinAndKey(this.pinValue, this.pinForm.form_key);
        const config = this.$member.config.player();
        console.log('hey!', config)
        this.active = false
			}
		}
	}
