
export default {
    props: {
        table_of_contents: Array
    },
    data() {
        return {
            showing: false
        }
    },
    methods: {
        resetAccordion() {
            this.table_of_contents.forEach((item) => {
                console.log(item);
                item.show = false;
            })
        },
        toggleItem(i) {
            if (!this.table_of_contents[i].show){
                if (this.showing) this.resetAccordion(); // only reset if necessary
                else this.showing = true;
                this.table_of_contents[i].show = true;
            } else {
                this.showing = false;
                this.table_of_contents[i].show = false;
            }
            this.$forceUpdate(); // update DOM
        }
    },
}
