
export default {
    props: {
        id: Number,
        isFavorited: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            collections: [],
            collectionsInCurrentCourse: [],
            errorMessage: "",
            archived: false,
            favorited: false,
            newCollection: false,
            newCollectionLabel: "",
        }
    },
    methods: {
        async removeCollectionFromCourse(collection) {
            try {
                await this.$member.collections.removeCourseFromCollection(collection.id, this.id);
                this.collectionsInCurrentCourse = await this.$member.collections.getCollectionsForItem(this.id);
                if (this.collectionsInCurrentCourse.length == 1) this.archived = true;
            } catch(e) {
                console.log("error archiving collection:", e);
            }
        },
        async archiveCourseFromCollections() {
            try {
                await this.$member.collections.archiveItem(this.id);
                this.collectionsInCurrentCourse = await this.$member.collections.getCollectionsForItem(this.id);
                if (this.collectionsInCurrentCourse.length == 1) this.archived = true;
            } catch(e) {
                console.log("error archiving collection:", e);
            }
        },
        async addCourseToCollection(collection) {
            try {
                await this.$member.collections.addCourseToCollection(collection.id, [this.id]);
                this.collectionsInCurrentCourse = await this.$member.collections.getCollectionsForItem(this.id);
                this.archived = false;
            } catch(e) {
                console.log("error adding course to collection:", e);
            }
        },
        async removeCourseFromArchive() {
            try {
                /*await this.$member.collections.addCourseToCollection(49, [85]);
                await this.$member.collections.addCourseToCollection(50, [85]);*/
                this.collectionsInCurrentCourse = await this.$member.collections.getCollectionsForItem(this.id);
                this.archived = false;
            } catch(e) {
                console.log("error restoring course:", e);
            }
        },
        async addToFavorites() {
            await this.$member.favorite.addCourse(this.id);
            this.favorited = true;
        },
        async removeFromFavorites() {
            await this.$member.favorite.removeCourse(this.id);
            this.favorited = false;
        },
        async createNewCollection() {
            try {
                await this.$member.collections.add(this.newCollectionLabel);
                this.collections = await this.$member.collections.list();
                const newCollection = this.collections[this.collections.length - 1];
                this.addCourseToCollection(newCollection);
                this.collections = await this.$member.collections.list();
                this.newCollection = false;
                this.archived = false;
            } catch(e) {
                console.log("error creating new collection:", e);
            }
        },
        async initCollections() {
            this.collections = await this.$member.collections.list();
            this.collectionsInCurrentCourse = await this.$member.collections.getCollectionsForItem(this.id);
            if (this.collectionsInCurrentCourse.length == 1 && this.collectionsInCurrentCourse[0].is_archive) this.archived = true;
        }
    },
    mounted() {
        try {
            this.initCollections();
            // testing below

            // console.log("collections:", this.collections);

            /*for (const collection of this.collections) {
                await this.$member.collections.remove(collection.id);
            }
            await this.$member.collections.add('my-new-collection-1');
            await this.$member.collections.add('my-new-collection-2');*/
            
            /* this.collectionsInCurrentCourse = await this.$member.collections.getCollectionsForItem(85);
            await this.$member.collections.addCourseToCollection(49, [85]);
            await this.$member.collections.addCourseToCollection(50, [85]);
            this.collectionsInCurrentCourse = await this.$member.collections.getCollectionsForItem(85);
            console.log("current course", this.collectionsInCurrentCourse);
            for (const collection of this.collections) {
                console.log("collection:", collection);
                console.log("included?",this.collectionsInCurrentCourse.includes(collection));
            }*/
        } catch (e) {
            console.error('error during collection management', e);
        } 
        this.favorited = this.isFavorited;
    }
}
