import { render, staticRenderFns } from "./hero.vue?vue&type=template&id=498e81c0&scoped=true"
import script from "./hero.vue?vue&type=script&lang=js"
export * from "./hero.vue?vue&type=script&lang=js"
import style0 from "./hero.vue?vue&type=style&index=0&id=498e81c0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "498e81c0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ButtonOutlineLight: require('/usr/src/app/components/button/outline-light.vue').default,ModalEducator: require('/usr/src/app/components/modal/educator.vue').default,ModalBasic: require('/usr/src/app/components/modal/basic.vue').default})
