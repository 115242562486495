
    export default {
        props: {
            id: Number,
            itj: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                permalink: null,
                emailUrl: null,
                facebookUrl: null,
                twitterUrl: null
            }
        },
        methods: {
            async getCourseUrls() {

                const href = await this.$course.getCourseInfo(this.id, ["perma_link"]);
                this.permalink = href.perma_link;
                this.emailUrl = `mailto:?subject=You\'re Going to Dig This&body=Check out this course at TrueFire, I think you will like it: ` + this.permalink;
                this.facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=` + this.permalink + `c` + this.id;
                this.twitterUrl = `https://twitter.com/intent/tweet?url=` + this.permalink + `c` + this.id;
            },
            async getItjUrls() {
                const href = await this.$itj.getItjInfo(this.id, ["perma_link"]);
                this.permalink = href.perma_link;
                this.emailUrl = `mailto:?subject=You\'re Going to Dig This&body=Check out this Jam at TrueFire, I think you will like it: ` + this.permalink;
                this.facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=` + this.permalink + `c` + this.id;
                this.twitterUrl = `https://twitter.com/intent/tweet?url=` + this.permalink + `c` + this.id;
            }
        },
        created() {
            if (this.itj) this.getItjUrls();
            else this.getCourseUrls();
        }
    }
