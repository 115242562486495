
export default {
    props: {
        text: {
            type: String,
            default: `Buy the Download`
        },
        icon: {
            type: String,
            default: `download`
        },
        modalTitle: {
            type: String,
            default: `Download Now`
        },
        productInfo: Array
    },
    data() {
        return {
            buyModal: false
        }
    }
}
