
export default {
    data() { 
        return {
            items: false,
            allAccessImg: `https://d2xkd1fof6iiv9.cloudfront.net/images/all-access/aabanner.png`,
            allAccessText: `All Access Students can stream TrueFire's entire course library online or via our mobile apps, plus get exclusive discounts, freebies, and more!`,
            learnMoreUrl: `https://truefire.com/all-access/upgrade-now`,
        }
    },
    async created() {
        const response = await this.$item.getItem(8004, ["truefire_item"]);
        this.items = [response.truefire_item];
    }
}
