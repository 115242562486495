
	export default {
		data(){
			return {
				desktop: [
					{label: `Windows`, icon: `windows`, url: `/apps/download-windows`},
					{label: `Mac`, icon: `apple`, url: `/apps/download-mac`},
					{label: `Linux`, icon: `linux`, url: `/player/TrueFireInstaller.AppImage`}
				],
				mobile: [
					{label: `App Store`, icon: `apple`, url: `https://itunes.apple.com/us/app/truefire/id690143001?mt=8`},
					{label: `Google Play`, icon: `google-play`, url: `https://play.google.com/store/apps/details?id=com.truefire.android3`},
				],
			}
		},
	}
