
	export default {
		props: {
			paginationData: Object,
			sortData: Array,
			category: String,
		},
		data() {
			return {
				numPerPage: 8,
				pageNumber: 1,
				offset: 0,
				endPage: 3,
			}
		},
		watch: {
			numPerPage(n) {
				if (n * this.pageNumber > this.paginationData.totalRecords) this.pageNumber = 1; 
				this.$emit("update-pagination", n, this.pageNumber);
			},
			pageNumber(p) {
				// edge cases
				if (p == 1 || p == 2) this.offset = 0;
				else if (p == this.paginationData.totalPages) this.offset = this.pageNumber - 3;
				else this.offset = this.pageNumber - 2;
                this.$emit("update-pagination", this.numPerPage, p);
            }
        },
        computed: {
            prevBtn() {
                return this.pageNumber == 1 ? 'disabled' : '';
            },
            nextBtn() {
                return this.pageNumber == this.paginationData.totalPages ? 'disabled' : '';
            },
            paginationText() {
                if (this.pageNumber == this.paginationData.totalPages) return "Showing " + ((this.pageNumber - 1) * this.numPerPage + 1) + " - " + this.paginationData.totalRecords + " of " + this.paginationData.totalRecords;
                return "Showing " + ((this.pageNumber - 1) * this.numPerPage + 1) + " - " + (this.pageNumber * this.numPerPage) + " of " + this.paginationData.totalRecords;
            }
        },
        methods: {
            previous() {
                this.pageNumber -= 1;
            },
            next() {
                this.pageNumber += 1;
            },
            sendSortVal(val) {
                this.$emit("update-sort", val);
            }
        },
        created() {
            this.pageNumber = this.paginationData.pageNumber;
        },
    }
