
    export default {
        props: {
            educator: Object,
            loggedIn: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                isFavorited: false,
            }
        },
        computed: {
            favoriteText() {
                return this.isFavorited ? `Remove from Favorite Educators` : `Add to Favorite Educators`
            }
        },
        methods: {
            async toggleFavorite() {
                if (this.isFavorited) await this.$member.favorite.removeEducator(this.educator.id);
                else await this.$member.favorite.addEducator(this.educator.id); 
                this.isFavorited = !this.isFavorited;
            }
        },
        async created() {
            if (this.loggedIn) {
                const ed = await this.$educator.get(this.educator.id, "favorite");
                if (ed) this.isFavorited = ed.is_favorite;
            }
        }
    }
