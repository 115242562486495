
	export default {
		data(){
			return {
				currentRave: 0,
			}
		},
		created(){
			setTimeout(this.flipSlide, 3000)
		},
		methods: {
			flipSlide(){
				this.currentRave++;
				if(this.currentRave >= this.$parent.educator.raves.length)
					this.currentRave = 0;
				setTimeout(this.flipSlide, 8000)
			}
		}
	}
