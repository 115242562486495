
	export default {
		created(){
			try {
				const yotpoApi = new Yotpo.API(yotpo)
				this.$nextTick(() => {
					yotpoApi.refreshWidgets()
				})
			} catch(err) {}
		}
	}
