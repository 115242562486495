
export default {
    data() {
        return {
            title: `You Might Also Like`,
            recommendations: false
        }
    },
    async created() {
        this.recommendations = await this.$itj.getItjRecommendations(['id', 'title', 'link',
            {
                'player_cover':{
                    'art': '169_1920'
                }
            }
        ]);
    }
}
