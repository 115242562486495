
    export default {
        props: ['input', 'lazy', 'configId'],
        data() {
            return {
                bannerAspect: 'padding-bottom:30.26%;',
                data: {},
                containerWidth: 0
            }
        },
        computed: {
			imageSrc() {
				if (!this.data.image) return '';
				let url = new URL(this.data.image);
				let params = new URLSearchParams(url.search);
				params.set('width', this.containerWidth);
				url.search = params.toString();
				return url.toString();
			}
		},
        mounted() {
            this.containerWidth = this.$refs.container.offsetWidth;
        },
        async fetch() {
            if(this.configId) this.data = await this.$configs.get(this.configId)
        }
    }
