import { render, staticRenderFns } from "./raves.vue?vue&type=template&id=019486e8&scoped=true"
import script from "./raves.vue?vue&type=script&lang=js"
export * from "./raves.vue?vue&type=script&lang=js"
import style0 from "./raves.vue?vue&type=style&index=0&id=019486e8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "019486e8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EducatorPanel: require('/usr/src/app/components/educator/panel.vue').default})
