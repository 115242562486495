
	export default {
		props: ['mobile'],
		data() {
			return {
				loadingLesson: false,
				darkMode: false,
				items: false
			}
		},
		created() {
			console.log('product from toc', this.$store.state.player.product)
			if(this.$store.state.player.product.toc){
				const items = {}
				const tmp = []
				let tick = -1
				for (let i = 0; i < this.$store.state.player.product.toc.tree.length; i++) {
					if (!items[this.$store.state.player.product.toc.tree[i].pid]) {
						items[this.$store.state.player.product.toc.tree[i].pid] = []
						tick++
					}
					this.$store.state.player.product.toc.index[this.$store.state.player.product.toc.tree[i].id].index = tick
					if (this.$store.state.player.product.toc.index[this.$store.state.player.product.toc.tree[i].pid] && (this.$store.state.player.product.toc.index[this.$store.state.player.product.toc.tree[i].pid].description)) {
						this.$store.state.player.product.toc.index[this.$store.state.player.product.toc.tree[i].id].description = this.$store.state.player.product.toc.index[this.$store.state.player.product.toc.tree[i].pid].description
						this.$store.state.player.product.toc.index[this.$store.state.player.product.toc.tree[i].id].title = this.$store.state.player.product.toc.index[this.$store.state.player.product.toc.tree[i].pid].name
					}
					items[this.$store.state.player.product.toc.tree[i].pid].push(this.$store.state.player.product.toc.index[this.$store.state.player.product.toc.tree[i].id])
				}
				for (let i = 0; i < Object.keys(items).length; i++) tmp.push([])
				for (const id in items) {
					const tmpIndex = items[id][0].index
					tmp[tmpIndex] = items[id]
				}
				this.items = tmp
			}
		}
	}
