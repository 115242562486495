
	export default {
		data() {
			return {
				reviews: [
					{
						quote: "I've been a long-time TrueFire customer. The quality and content of the guitar lessons continues to improve. The variety of courses available is second to none. Truly something for everyone!",
						author: "Piker, TrueFire Student"
					},
					{
						quote: "TrueFire has the best instructors for all levels not only from this country but internationally as well. Their catalog of courses is superb. The apps are top-notch and offer many great features for learning, visualizing, and jamming with the various guitar lessons including being able to slow it down or loop it. The catalog of courses is awesome and greatly appreciated!",
						author: "Bburk Jr, TrueFire Student"
					},
					{
						quote: "I've been playing guitar for more than 50 years and still enjoy finding new ideas from TrueFire's selection of courses, which helps me to broaden my horizons.",
						author: "OldDogDave, TureFire Student"
					},
				]
			}
		}
	}
