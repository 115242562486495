
  export default {
    layout: 'tf',
    head: {
  		title: `TrueFire - Online Guitar Lessons`,
      link:[
        { rel: 'canonical', href: 'https://truefire.com' },
		  ],
  		meta: [
  			{ name: 'viewport', content: 'width=device-width, initial-scale=1' },
  			{ name: 'description', content: `Learn how to play guitar with free video guitar lessons and interactive guitar courses at TrueFire, the world's most comprehensive library of guitar instruction.` },
  			{ name: 'keywords', content: 'online guitar lessons, best online guitar lessons, guitar lessons online, best guitar lessons online' },
  			{ name: 'author', content: 'TrueFire' }
		  ],
	  },
  }
