
	export default {
		props: {
			input: {
				type: Object,
				required: false,
			},
			inputArray: {
				type: Array,
				required: false,
			},
			configId: {
				type: String,
				required: false
			},
			numRows: String
		},
		data() {
			return {
				templateData: false,
				/* number of rows based on vuetify breakpoints */
				oneOnly: "col-12",
				twoOnly: "col-6",
				threeOnly: "col-4",
				minOne: "col-12 col-sm-6 col-md-4",
				minTwoMaxThree: "col-6 col-md-4",
				minTwo: "col-6 col-md-3",
				rows: "col-12 col-sm-6 col-md-4", /* default all three */
			}
		},
		watch: {
			input() {
				this.updateInput();
			},
			inputArray() {
				this.updateInput();
			},
			configId() {
				this.updateInput();
			}
		},
		methods: {
			async updateInput() {
				if (this.input && this.input.items) {
					this.templateData = this.input.items;
					return
				} else if (this.inputArray) {
					this.templateData = this.inputArray;
					return
				}
				if (this.configId) {
					const config = await this.$configs.get(this.configId);
					this.templateData = config.items;
					return
				}
			}
		},
		fetch(){
			this.updateInput();
		},
		created() {
			switch(this.numRows) {
				case '1':
					this.rows = this.oneOnly;
					break;
				case '2':
					this.rows = this.twoOnly;
					break;
				case '3':
					this.rows = this.threeOnly;
				case '1-3':
					this.rows = this.minOne;
					break;
				case '2-3':
					this.rows = this.minTwoMaxThree;
					break;
				case '2-4':
					this.rows = this.minTwo;
					break;
				default:
					this.rows = this.minOne;
			}
		}
	}
