
	export default {
		data(){
			return {
				lesson: {},
				darkMode: false,
				materials: [],
				videos: [],
				showErrorReport: "false",
				reportText: "",
				canShowPdf: false,
			}
		},
		methods: {
			setup(){
				let hasPdf, hasGpx, hasMp3
				let didErrorBtn
				for (const id in this.lesson.assets) if (this.lesson.assets[id]) {
					if (this.lesson.assets[id].url && this.lesson.assets[id].type !== 'hls') {
						if (!hasPdf && this.lesson.assets[id].type === 'pdf') {
							const data = fetch(this.lesson.assets[id].url + '?url_only=1').then()
							if (data && data.url) this.lesson.assets[id].url = data.url
							if (!didErrorBtn) {
								this.lesson.assets[id].hasErrorButton = true
								didErrorBtn = true
							}
							this.materials.push(this.lesson.assets[id])
							hasPdf = true
						}
						if (!hasGpx && this.lesson.assets[id].type === 'gpx') {
							const data = fetch(this.lesson.assets[id].url + '?url_only=1').then()
							if (data && data.url) this.lesson.assets[id].url = data.url
							if (!didErrorBtn) {
								this.lesson.assets[id].hasErrorButton = true
								didErrorBtn = true
							}
							this.materials.push(this.lesson.assets[id])
							hasGpx = true
						}
						if (!hasMp3 && this.lesson.assets[id].type === 'mp3') {
							const data = fetch(this.lesson.assets[id].url + '?url_only=1').then()
							if (data && data.url) this.lesson.assets[id].url = data.url
							if (!didErrorBtn) {
								this.lesson.assets[id].hasErrorButton = true
								didErrorBtn = true
							}
							this.materials.push(this.lesson.assets[id])
							hasMp3 = true
						}
						else if (this.lesson.assets[id].type.includes('mp4')) {
							const data = fetch(this.lesson.assets[id].url + '?url_only=1').then()
							if (data && data.url) this.lesson.assets[id].url = data.url
							if (!didErrorBtn) {
								this.lesson.assets[id].hasErrorButton = true
								didErrorBtn = true
							}
							this.materials.push(this.lesson.assets[id])
						}
					}
				}
			},
			canShowPdf(){
				return process.client && (item.type == 'pdf' &&
					!navigator.userAgent.match(/Android/i) &&
					(!lesson.soundslice || (lesson.soundslice && !lesson.soundslice.syncpoints)) && 
					lesson.id != 14802 && lesson.id != 24388 && lesson.id != 11495 && product.id != 165 && lesson.id != 24444 && product.id != 188)
			}
		},
		async created() {
			const data = await this.$player.getLesson()
			try {
				this.lesson = data.lesson
				this.setup()
			} catch(err){ }
		}
	}
