
const __default__ = {
    props: {
        text: String,
        url: String,
        image: String,
        numLines: {
            type: Number,
            default: 6
        },
        target: {
            type: String,
            default: `_self`
        }
    }
}

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "35fee0b4": (_vm.numLines)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__