
	export default {
		data(){
			return {
				product: {},
				thumb: false
			}
		},
		created(){
			// const id = this.$store.state.player.product.instructor[0].link.substring(this.$store.state.player.product.instructor[0].link.indexOf('/e') + 2)
			// this.thumb = `https://d2xkd1fof6iiv9.cloudfront.net/images/educators/${id}/square_200.jpg`
		}
	}
