
	export default {
		data(){
			return {
				items: [],
				configData: {}
			}
		},
		methods: {
			addItem(label, url){
				this.items.push({label: label, url: url})
			},
			goBack(){
				if(process.client) history.back()
			}
		},
		async created() {
			this.configData = await this.$player.getConfig()
			if(!this.configData.whitelabel){
				const data = await this.$player.getProduct()
				this.addItem('Home', '/')
				this.addItem('Courses', '/courses')
				try {
					this.addItem(data.product.name, `/c${data.product.id}`)
					this.addItem(`${data.lesson.section_name}: ${data.lesson.subname}`, `/c${data.lesson.id}`)
				} catch(err){ }
			}
		}
	}
