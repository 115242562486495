import { render, staticRenderFns } from "./all-access.vue?vue&type=template&id=44447655"
import script from "./all-access.vue?vue&type=script&lang=js"
export * from "./all-access.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TfImg: require('/usr/src/app/components/tf/img.vue').default,TextLearnMore: require('/usr/src/app/components/text/learn-more.vue').default,BuyPrice: require('/usr/src/app/components/buy/price.vue').default,TfFrame: require('/usr/src/app/components/tf/frame.vue').default})
