
export default {
	props: {
		placeholder: {
			type: String,
			default: `Search Lessons, Courses, and More`,
		}
	},
	data() {
		return {
			searchQuery: '',
			didSearch: false
		}
	},
	methods: {
		doSearch(){
			this.didSearch = this.searchQuery ? true : false
			this.$emit("search-content", this.searchQuery);
		},
		clearSearch(){
			this.searchQuery = ''
			this.doSearch()
		},
	}
}
