
	export default {
		props: {
			data: Object,
			i: Number,
			lazy: Boolean,
			preload: Number
		},
		data(){
			return {
				image: false
			}
		},
		created(){
			if(this.data && (this.data.image)){
				let tempImage = this.data.image + '?width=416&format=webp'
				tempImage = tempImage.replace('d2xkd1fof6iiv9', 'df4emreqpcien')
				this.image = tempImage
			}
		}
	}
