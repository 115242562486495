
	export default {
		props: ['product', 'placeholder'],
		data(){
			return {
				storeType: 'course'
				// storeType: (this.product.title.toLowerCase().includes('in the jam') || this.product.title.toLowerCase().includes('(premium)')) ? 'itj' : 'course'
			}
		},
		created(){
			if(this.product && (this.product.title)){
				if(this.product.title.toLowerCase().includes('in the jam') || this.product.title.toLowerCase().includes('(premium)'))
					this.storeType = 'itj'
				this.$parent.storeType = this.storeType
			}
		}
	}
