
	export default {
		props: {
			item: Object,
			url: String,
			loggedIn: {
				type: Boolean,
				default: false
			},
			hoverLink: {
				type: Boolean,
				default: true
			},
			showStars: {
				type: Boolean,
				default: true
			},
			showButtons: {
				type: Boolean,
				default: true
			},
		},
		data() {
			return {
				moreInfo: false,
				showCollections: false,
			}
		},
		methods: {
			triggerCollectionsModal() {
				if (!this.loggedIn) location.href = `/login`;
				else this.showCollections = true;
			}
		}
	}

