
	export default {
		props: {
			id: Number,
		},
		data() {
			return {
				course: {},
				date: "",
			}
		},
		methods: {
			formatDate(unix) {
				const dateString = new Date(unix * 1000);
				this.date = dateString.toLocaleDateString();
			},
			async getCourse() {
				const dataObj = {
					"course": {
						"id": this.id,
						"fields":["id", "url", "intro_video", 
						{
							"play_cover": {
								"art": "169_1920"
							}
						},
						"truefire_items", "title", "yotpo", "description", "release_date:unixtime", "includes"]
					}
				};

				this.$nexus(dataObj).then(result => {
					this.course = result.course;
					this.formatDate(result.course.release_date);
				}).catch(error => {
					this.errorMessage = error
				})
			},
		},
		created() {
			this.getCourse();
		}
	}
