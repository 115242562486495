
    export default {
        props: {
            title: String,
            model: String,
            size: String
        },
        methods: {
            closeModal() {
                if (!this.model) this.$emit('close');
                this.$parent[this.model] =  'false';
            }
        }
    }
