import { render, staticRenderFns } from "./page_old.vue?vue&type=template&id=073f176e"
import script from "./page_old.vue?vue&type=script&lang=js"
export * from "./page_old.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VideoPlayer: require('/usr/src/app/components/video/player.vue').default,CourseFreeLessons: require('/usr/src/app/components/course/free-lessons.vue').default,TextViewMore: require('/usr/src/app/components/text/view-more.vue').default,CourseTocAccordion: require('/usr/src/app/components/course/toc-accordion.vue').default,CourseRecommendations: require('/usr/src/app/components/course/recommendations.vue').default,TfYotpoReviews: require('/usr/src/app/components/tf/yotpo-reviews.vue').default,ButtonPlay: require('/usr/src/app/components/button/play.vue').default,ThumbnailStars: require('/usr/src/app/components/thumbnail/stars.vue').default,ButtonDownloadOrPreorder: require('/usr/src/app/components/button/download-or-preorder.vue').default,BuyNow: require('/usr/src/app/components/buy/now.vue').default,BuyAllAccess: require('/usr/src/app/components/buy/all-access.vue').default,ButtonGiftAddShare: require('/usr/src/app/components/button/gift-add-share.vue').default,CourseDetails: require('/usr/src/app/components/course/details.vue').default,CourseEducatorSummary: require('/usr/src/app/components/course/educator-summary.vue').default,CourseDownloadAndMore: require('/usr/src/app/components/course/download-and-more.vue').default})
