
	export default {
		data () {
			return {
				linkClass: `d-block py-1 px-3 text-nowrap text-dark`,
				darkMode: false,
				mobileNavShowing: false,
				currMobileNav: `main`,
				nav: [
					{
						label: "Courses",
						url: '/courses',
						subShow: false,
						megaNav: [
							{
								label: 'Guitar Styles',
								items: [
									{label: 'Blues', url: '/guitar-lessons/blues'},
									{label: 'Blues Rock', url: '/guitar-lessons/blues%20rock'},
									{label: 'Jazz', url: '/guitar-lessons/jazz'},
									{label: 'Jazz Blues', url: '/guitar-lessons/jazz%20blues'},
									{label: 'Rock', url: '/guitar-lessons/rock'},
									{label: 'Hard Rock/Metal', url: '/guitar-lessons/hard%20rock'},
									{label: 'Country', url: '/guitar-lessons/country'},
									{label: 'Acoustic', url: '/guitar-lessons/acoustic'},
									{label: 'Singer-Songwriter', url: '/guitar-lessons/Singer-Songwriter'},
									{label: 'Fingerstyle', url: '/guitar-lessons/fingerstyle'},
									{label: 'Classical', url: '/guitar-lessons/classical'},
									{label: 'Funk', url: '/guitar-lessons/funk'},
									{label: 'R&B/Soul', url: '/search/?q=r%20and%20b'},
									{label: 'Americana', url: '/guitar-lessons/americana'},
									{label: 'Rockabilly', url: '/guitar-lessons/rockabilly'},
									{label: 'Bluegrass', url: '/search/?q=Bluegrass '}
								]
							},
							{
								label: 'Other',
								items: [
									{label: 'Bass', url: '/guitar-lessons/bass'},
									{label: 'Banjo', url: '/guitar-lessons/banjo'},
									{label: 'Ukulele', url: '/guitar-lessons/ukulele'},
									{label: 'Harmonica', url: '/guitar-lessons/harmonica'},
									{label: 'Other', url: '/guitar-lessons/other'}
								]
							},
							{
								label: 'Learn to Play',
								items: [
									{label: 'Beginner', url: '/guitar-lessons/beginner'},
									{label: 'Late Beginner', url: '/guitar-lessons/late%20beginner'},
									{label: 'Songs', url: '/guitar-song-lessons'}
								]
							},
							{
								label: 'Guitar Topics',
								items: [
									{label: 'Rhythm', url: '/guitar-lessons/rhythm'},
									{label: 'Soloing', url: '/guitar-lessons/soloing'},
									{label: 'Applied Theory', url: '/search?q=applied%20theory'},
									{label: 'Essential Reference', url: '/search?q=essential%20reference'},
									{label: 'Style Studies', url: '/search?q=style%20studies'},
									{label: 'Technique', url: '/search?q=technique'},
									{label: 'Chords & Progressions', url: '/search?q=chords%20and%20progressions'},
									{label: 'Slide', url: '/guitar-lessons/slide'},
									{label: 'Scales & Modes', url: '/search?q=scales%20and%20modes'},
									{label: 'Pentatonic', url: '/guitar-lessons/pentatonic'},
									{label: 'Improvisation', url: '/guitar-lessons/improvisation'},
									{label: 'CAGED', url: '/guitar-lessons/caged'},
									{label: 'Chord Melody', url: '/guitar-lessons/chord%20melody'},
									{label: 'Songwriting', url: '/guitar-lessons/songwriting'},
									{label: 'Workouts & Exercises', url: '/search?q=workouts%20and%20exercises'},
									{label: 'Tone & Gear', url: '/search?q=tone%20and%20gear'},
									{label: 'Arpeggios', url: '/guitar-lessons/arpeggios'},
									{label: 'Alternate Tunings', url: '/guitar-lessons/alternate%20tunings'}
								]
							},
							{
								label: 'Browse',
								items: [
									{label: 'All Courses', url: '/search/?q=Courses'},
									{label: 'Most Popular', url: '/search/?q=Most%20Popular'},
									{label: 'On Sale', url: '/sales-promos/'},
									{label: 'Pre-Orders', url: '/pre-orders/'},
									{label: 'Recommendations', url: '/recommendations/'},
									{label: 'Free Lessons', url: '/free-guitar-lessons'},
									{label: 'Curated Collections', url: '/collections'},
									{label: 'JamPlay Courses', url: '/search?q=JamPlay'},
								]
							},
							{
								label: 'Series',
								items: [
									{label: 'Learn & Play Guitar', url: '/search?q=Learn%20Play%20Guitar'},
									{label: 'Masterclasses', url: '/search?q=masterclasses'},
									{label: 'Practice Sessions', url: '/search?q=practice%20sessions'},
									{label: 'Guitar Heroes & Legends', url: '/search/?q=Play%20Like%20Legends'},
									{label: 'Essentials', url: '/guitar-lessons/essentials'},
									{label: 'Licks You Must Know', url: '/guitar-lessons/licks%20you%20must%20know'},
									{label: 'Trading Solos', url: '/search/?q=Trading%20Solos'},
									{label: 'Focus On', url: '/guitar-lessons/focus%20on'},
									{label: 'Survival Guides', url: '/guitar-lessons/survival%20guide'},
									{label: 'Fakebooks', url: '/search?q=Fakebooks'},
									{label: 'Guitar Gym', url: '/guitar-lessons/guitar%20gym'},
									{label: 'Guitar Lab', url: '/guitar-lessons/guitar%20lab'},
									{label: 'Take 5', url: '/search?q=Take%205'}
								]
							},
						]
					},
					{
						label:"Songs",
						url: '/song-directory',
						subShow: false,
						subNav: [
							{
								"label":"About",
								"url":"/song-lessons"
							},
							{
								"label":"All Song Lessons",
								"url":"/song-directory/"
							},
			
						]
					},
					{
						label:"Jams",
						url: '/jams',
						subShow: false,
						subNav: [
							{
								"label":"About",
								"url":"/jams"
							},
							{
								"label":"In The Jam",
								"url":"/in-the-jam"
							},
							{
								"label":"Multi-Track Audio Jams",
								"url":"/multi-track-audio-jams"
							},
							{
								"label":"Jam Packs",
								"url":"/jam-packs"
							},
							{
								"label":"Free Jam Tracks",
								"url":"/jam-tracks"
							}
						]
					},
					{
						label: "Sales",
						url: '/sales-promos',
						subShow: false,
						subNav: [
							{
								label: 'All Access',
								url: '/all-access'
							},
							{
								label: 'Fire Sales',
								url: '/sales-promos'
							},
							{
								label: 'Pre-Orders',
								url: '/pre-orders'
							},
							{
								label: 'Artist Bundles',
								url: '/bundles'
							},
							{
								label: '$5 Downloads',
								url: '/five-dollar-downloads'
							}
						]
					},
					{
						label: "Educators",
						url: "/educators",
						subShow: false,
						educators: ['Adam Levy', 'Alex Skolnick', 'Ana Popovic', 'Andy Aledort', 'Andy McKee', 'Andy Timmons', 'Andy Wood', 'Angus Clark', 'Anthony Stauffer', 'Ariane Cap', 
							'Ariel Posen', 'Brad Carlton', 'Brent Mason', 'Brooks Robertson', 'Carl Verheyen', 'Chris Buono', 'Christie Lenée', 'Clive Carroll', 'Corey Congilio', 'David Grissom', 
							'David Hamburger', 'David Wallimann', 'Diego Figueiredo', 'Dweezil Zappa', 'Eric Haugen', 'Fareed Haque', 'Frank Vignola', 'Greg Koch', 'Gretchen Menn', 'Henry Johnson', 
							'Howard Morgen', 'Ian Stich', 'Jack Ruch', 'Jason Loughlin', 'JD Simo', 'Jeff McErlain', 'Jeff Scheetz', 'Jennifer Batten', 'Jimmy Vivino', 'John Oates', 'John Pizzarelli',
							'Johnny Hiland', 'Josh Smith', 'Keb Mo', 'Keith Wyatt', 'Kelly Richey', 'Kenny Lee Lewis', 'Kid Andersen', 'Kirk Fletcher', 'Larry Carlton', 'Lindsay Ell', 
							'Livingston Taylor', 'Mark Whitfield', 'Martin Taylor', 'Marty Friedman', 'Matt Schofield', 'Matthew Lee', 'Michael Palmisano', 'Mike Dawes', 'Mike Zito', 'Mimi Fox', 
							'Muriel Anderson', 'Neil Zaza', 'Oz Noy', 'Pat Martino', 'Paul Brown', 'Redd Volkaert', 'Robben Ford', 'Robbie Calvo', 'Robert Jones', 'Samantha Fish', 'Scott Sharrard', 
							'Sean McGowan', 'Shane Theriot', 'Sheryl Bailey', 'Sonny Landreth', 'Sophie Lloyd', 'Steve Vai', 'Stu Hamm', 'Tim Lerch', 'Tim Pierce', 'Tommy Emmanuel', 'Tracii Guns',
							'Walter Trout'],
						ids: [157, 16, 4559, 40, 4397, 4330, 4483, 4258, 4266, 4365, 4544, 7, 4661, 4304, 4223, 4184, 4427, 4541, 4200, 46, 48, 4305, 4426, 4349, 4616, 171, 3424, 3773, 4659, 4422, 69, 
							4645, 4612, 4214, 4456, 224, 1091, 274, 4609, 4679, 4803, 4314, 4498, 4677, 35, 4280, 4637, 4496, 4509, 3922, 4831, 4635, 4505, 4644, 4676, 4494, 4485, 4576, 4634, 4324, 3928, 
							161, 4234, 3946, 4313, 4321, 4470, 3776, 4181, 4486, 4655, 4497, 4416, 1985, 4198, 4299, 4618, 4387, 4127, 4539, 4603, 3861, 4819, 4633]
						},
					{
						label: "Learning Paths",
						url: '/learning-paths',
						subShow: false,
						subNav: [
							{
								"label":"About",
								"url":"/learning-paths"
							},
							{
								"label":"Blues",
								"url":"/a13"
							},
							{
								"label":"Jazz",
								"url":"/a3"
							},
							{
								"label":"Rock",
								"url":"/a9"
							},
							{
								"label":"Acoustic",
								"url":"/a12"
							},
							{
								"label":"Country",
								"url":"/a10"
							},
							{
								"label":"Bass",
								"url":"/a14"
							}
						]
					},
					{
						label: "Channels",
						url: "/channels"
					},
					{
						label: "More",
						subShow: false,
						subNav: [
							{
								"label":"Free Downloads",
								"url":"/free-downloads"
							},
							{
								"label":"Live",
								"url":"/live"
							},
							{
								"label":"JamPlay Live Archive",
								"url":"/jamplay/live-archive"
							},
							{
								"label":"Apps",
								"url":"/apps"
							},
							{
								"label":"Learning Tools",
								"url":"/learning-tools"
							},
							{
								"label":"Private Lessons",
								"url":"/private-lessons"
							},
							{
								"label":"Blog",
								"url":"https://blog.truefire.com"
							},
							{
								"label":"Riff Journal",
								"url":"/riff/"
							},
							{
								"label":"Refer a Friend",
								"url":"/refer/"
							},
							{
								"label":"Gift Certificates",
								"url":"/gift-certificates/"
							},
							{
								"label":"Community",
								"url":"/community"
							}
						]
					}
				],
				searchPlaceholder: `Search Lessons, Courses, and More`,
				searchQuery: ``,

			}
		},
		methods: {
			toggleMobileDropdown() {
				this.mobileNavShowing = !this.mobileNavShowing;
				this.currMobileNav = "main";
			},
			navToNewPage(item) {
				window.location.href = item.url ? item.url : '/#';
			},
			doSearch(){
				if(this.searchQuery) location.href = `/search/?q=${this.searchQuery}`
			},
		},
		async fetch(){
			this.darkMode = await this.$member.config.darkMode()
		}
	}
