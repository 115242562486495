import { render, staticRenderFns } from "./course-more.vue?vue&type=template&id=28ebd82a"
import script from "./course-more.vue?vue&type=script&lang=js"
export * from "./course-more.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VideoPlayer: require('/usr/src/app/components/video/player.vue').default,BuyPrice: require('/usr/src/app/components/buy/price.vue').default,ThumbnailStars: require('/usr/src/app/components/thumbnail/stars.vue').default,TextLearnMore: require('/usr/src/app/components/text/learn-more.vue').default})
