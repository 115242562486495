import { render, staticRenderFns } from "./lesson-tabs.vue?vue&type=template&id=6c48973c"
import script from "./lesson-tabs.vue?vue&type=script&lang=js"
export * from "./lesson-tabs.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PlayerMaterialsTab: require('/usr/src/app/components/player/materials-tab.vue').default,PlayerDescriptionTab: require('/usr/src/app/components/player/description-tab.vue').default})
