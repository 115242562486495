import { render, staticRenderFns } from "./list-float.vue?vue&type=template&id=8ec9b968"
import script from "./list-float.vue?vue&type=script&lang=js"
export * from "./list-float.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TextList: require('/usr/src/app/components/text/list.vue').default,TextViewMore: require('/usr/src/app/components/text/view-more.vue').default})
