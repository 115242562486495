
	export default {
		data(){
			return {
				nav: [],
				hasMaterials: false,
				current: 0,
				buttonStyleActive: ``,
				buttonStyleInactive: ``,
				buttonClassActive: ``,
				buttonClassInactive: ``,
				buttonClassHover: ``,
				darkMode: false
			}
		},
		methods: {
			pushTab(label) {
				this.nav.push({ label, hover: false })
			}
		},
		created(){
			if (this.$store.state.player.lesson.assets) for (const key in this.$store.state.player.lesson.assets)
				if (this.$store.state.player.lesson.assets[key].type && (this.$store.state.player.lesson.assets[key].type !== 'hls') && (this.$store.state.player.lesson.assets[key].type != 'other'))
					this.hasMaterials = true
			if (this.hasMaterials) this.pushTab('Lesson Materials')
			this.pushTab('Description')
			// if(!location.href.includes('?educator_preview'))
			// 	this.pushTab('Recommendations')
			this.buttonStyleActive = `border-bottom-color:transparent !important;`
			this.buttonStyleInactive = `border-top-color:transparent !important;
				border-left-color:transparent !important;
				border-right-color:transparent !important;`
			this.buttonClassActive = `border ` + (this.darkMode ? 'text-light' : 'text-dark')
			this.buttonClassInactive = `border text-link`
			this.buttonClassHover = this.darkMode ? `border text-light bg-dark` : `border text-link bg-light`
		}
	}
