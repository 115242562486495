
export default {
    props: {
        table_of_contents: Array
    },
    data() {
        return {
            shortlist: [],
            visibleList: [],
            toggleText: `See Full List of Lessons`,
            showAll: false,
        }
    },
    methods: {
        toggleView() {

        }
    },
    created() {
        this.shortlist = this.table_of_contents.slice(0,15);
    }
}
