import { render, staticRenderFns } from "./educator-summary.vue?vue&type=template&id=3007b322"
import script from "./educator-summary.vue?vue&type=script&lang=js"
export * from "./educator-summary.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TextLearnMore: require('/usr/src/app/components/text/learn-more.vue').default,ButtonOutlineLight: require('/usr/src/app/components/button/outline-light.vue').default,TfFrame: require('/usr/src/app/components/tf/frame.vue').default})
