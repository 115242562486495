
  export default {
	data(){
	  return {
		flexContainer: `d-flex position-absolute top-0 start-0 w-100 h-100 justify-content-center align-items-center`,
		showingMore: false,
		subscribeModal: false
	  }
	},
	methods: {
		pluralName(input){
			return `${this.$parent.educator.authorfirstname}'s`
		}
	  },
	watch: {
		subscribeModal() {
			console.log("modal change", this.subscribeModal);
		}
	}
  }
