
  export default {
    props: {
      text: {
        type: String,
        default: ``,
      },
      icon: {
        type: String,
        default: `circle-question`
      },
      iconColor: String
    }
  }
  