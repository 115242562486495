
    export default {
        props: {
            giftTitle: {
                type: String,
                default: `How to Gift This Course`
            },
            shareTitle: {
                type: String,
                default: `Share This Course`
            },
            giftTooltip: {
                type: Boolean,
                default: true
            },
            itj: {
                type: Boolean,
                default: false
            },
            priceInfo: Array,
            productTitle: String,
            productId: Number,
            favorited: Boolean,
            collections: {
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {
                giftModal: false,
                collectionsModal: false,
                shareModal: false,
            }
        }
    }
