
  export default {
    props: {
      src: String
    },
    data() {
      return {
        width: null,
      }
    },
    created() {
      try {
        this.img = new Image();
        this.img.src = this.src;
        this.width = this.img.width;
      } catch {}
    }
  }
