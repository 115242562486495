
export default {
    props: {
        productInfo: Array
    },
    data() {
        return {
            description: `As an All Access Student, you can stream this course on any device. However, if you'd like to download this course for offline access and own it forever, you can purhcase this course now.`
        }
    },
}
