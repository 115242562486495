
export default {
    props: {
		productInfo: Array,
		showTooltip: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			infoTooltip: `You get a download of the course for offline access via our desktop & mobile apps plus streaming access to this course forever on any device.`,
			memberTooltip: `Your Price includes available TrueFire Cash and All Access Discount, and Owner Discount, if applicable. Adding multiple items to your cart can affect final calculations.`
		}
	},
	created() {
		console.log("truefire items", this.productInfo)
	}
}
