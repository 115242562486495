
	export default {
		data(){
			return {
				id: 'id_' + this.$parent.educator.bandsintown.substring(0, this.$parent.educator.bandsintown.indexOf('-'))
			}
		},
		created(){
			const script = document.createElement('script')
			script.setAttribute('src', 'https://widget.bandsintown.com/main.min.js')
			document.head.appendChild(script)
		}
	}
