
  import Player from '~/components/player/page.vue';
  import Course from '~/components/course/page.vue';
  import Educator from '~/components/educator/page.vue';
  import ITJ from '~/components/itj/page.vue';
  

  export default {
    async asyncData({ app }) {
      let currentComponent = null;

      // Retrieve the key from the app context
      const key = app.$layoutKey;
      if (!key) return { currentComponent };
      const keyId = key;
      // const keyId = key.substring(1);

      // Determine the component based on the key type change to lower case
      const keyType = key.charAt(0);
      switch (keyType) {
        case 'v':
          currentComponent = 'PlayerComponentWrapper';
          break;
        case 'a':
          currentComponent = 'PlayerComponentWrapper'; // Use client-only for 'a'
          break;
        case 'c':
          currentComponent = 'CourseComponentWrapper';
          break;
        case 'e':
          currentComponent = 'EducatorComponentWrapper';
          break;
        case 'j':
          currentComponent = 'ITJComponentWrapper';
          break;
        default:
          currentComponent = 'div';
          break;
      }

      return { currentComponent, keyId };
    },
    data() {
      return {
        currentComponent: null,
        keyId: null
      };
    },
    computed: {
      wrappedComponent() {
        // Wrap the component in 'client-only' if the key starts with 'a'
        if (this.currentComponent === 'PlayerComponentWrapper') {
          return {
            functional: true,
            render(h, { props }) {
              return h('client-only', [
                h(Player, { props: { keyId: props.keyId } })
              ]);
            },
            props: ['keyId']
          };
        }
        return this.currentComponent;
      }
    },
    created() {
      console.log('created - currentComponent:', this.currentComponent);
      console.log('created - keyId:', this.keyId);
    },
    mounted() {
      console.log('mounted - currentComponent:', this.currentComponent);
      console.log('mounted - keyId:', this.keyId);
    },
    components: {
      PlayerComponentWrapper: {
        functional: true,
        props: ['keyId'],
        render(h, { props }) {
          return h('div', [
            h(Player, { props: { keyId: props.keyId } })
          ]);
        }
      },
      CourseComponentWrapper: {
        functional: true,
        props: ['keyId'],
        render(h, { props }) {
          return h('div', [
            h(Course, { props: { keyId: props.keyId } })
          ]);
        }
    },
    EducatorComponentWrapper: {
      functional: true,
      props: ['keyId'],
      render(h, { props }) {
        return h('div', [
          h(Educator, { props: { keyId: props.keyId } })
        ]);
      }
      },
      ITJComponentWrapper: {
        functional: true,
        props: ['keyId'],
        render(h, { props }) {
          return h('div', [
            h(ITJ, { props: { keyId: props.keyId } })
          ]);
        }
      },
      EducatorComponentWrapper: {
        functional: true,
        props: ['keyId'],
        render(h, { props }) {
          return h('div', [
            h(Educator, { props: { keyId: props.keyId } })
          ]);
        }
      }
    },
    layout(ctx) {
      const key = ctx.app.$layoutKey;
      if(!key) return 'default';
      const keyType = key.charAt(0);
      switch (keyType) {
        case 'v':
          return 'player';
        case 'a':
          return 'whitelabel';
        case 'c':
        return 'tf';
      case 'e':
          return 'tf';
        case 'j':
          return 'tf';
        case 'e':
          return 'tf';
        default:
          return 'default';
      }
    }
  }
  