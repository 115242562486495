
import axios from "axios";
	export default {
		data() {
			return {
				plans: [
					{
						title: `Free`,
						price: `0`,
						button: {
							label: `Start Your Free Trial`,
							url: `/login?join`,
							subtle: false
						},
						details: [
							`14 Day All Access Trial`
						]
					},
					{
						title: `All Access Monthly Plan`,
						subTitle: `Get Started Now`,
						price: `29`,
						button: {
							label: `Learn More`,
							url: `/all-access/upgrade-now`,
							subtle: true
						},
						details: [
							`All Access Streaming`,
							`80,000+ Online Guitar Lessons`,
							`30,000+ Tabs & Notation`,
							`20,000+ Jam Tracks`,
							`New Courses Weekly`,
							`Free Monthly Download`,
							`20% Discount on Purchases`,
							`Much, Much More`
						]
					},
					{
						title: `All Access Annual Plan`,
						subTitle: `Save $99 Per Year`,
						price: `149`,
						button: {
							label: `Learn More`,
							url: `/all-access/upgrade-now`,
							subtle: true
						},
						details: [
							`All Access Streaming`,
							`80,000+ Online Guitar Lessons`,
							`30,000+ Tabs & Notation`,
							`20,000+ Jam Tracks`,
							`New Courses Weekly`,
							`Free Monthly Download`,
							`20% Discount on Purchases`,
							`Much, Much More`
						]
					}
				]
			}
		},
		async created() {
			const postObj = {
				"items":{
					"id": [8004,8002],
					"fields": ["itemname", "price", "stored_price"]
				}
			}
			this.$nexus(postObj).then((data)=>{
				const result = data.items;
				if(result){
					if(result[0] && result[1]){
						this.plans[1].title = result[0].itemname.replace('TrueFire ', '');
						this.plans[1].price = parseInt(result[0].price);
						this.plans[2].title = result[1].itemname.replace('TrueFire ', '');;
						this.plans[2].price = parseInt(result[1].price);
					}
				}
			});			
		}
	}
