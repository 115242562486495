import { render, staticRenderFns } from "./page.vue?vue&type=template&id=443c936d"
import script from "./page.vue?vue&type=script&lang=js"
export * from "./page.vue?vue&type=script&lang=js"
import style0 from "./page.vue?vue&type=style&index=0&id=443c936d&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AuthPinModal: require('/usr/src/app/components/auth/pin-modal.vue').default,SearchByTag: require('/usr/src/app/components/search/by-tag.vue').default,SearchBarRelative: require('/usr/src/app/components/search/bar-relative.vue').default,ThumbnailCourse: require('/usr/src/app/components/thumbnail/course.vue').default,GridDynamic: require('/usr/src/app/components/grid/dynamic.vue').default,SearchSortPaginate: require('/usr/src/app/components/search/sort-paginate.vue').default})
